<template>
  <div>

    <section class="header-haberler">

      <h3>{{lang.duyurularve }}</h3>
        <h2>{{lang.bizdenhaberler }}</h2>

    </section>

    <section class="duyurular-haberler">
      <div id="duyurular">
        <router-link v-for="(item, index) in news" class="duyuru" :to="'/duyuru/'+item.seo"
                     data-aos="fade-up"
                     data-aos-offset="250"
                     data-aos-delay="0"
                     :data-aos-duration="'5'+index+'0'"
                     data-aos-easing="ease-in"
                     data-aos-mirror="true"
                     data-aos-once="false"
                     data-aos-anchor-placement="center-bottom">
          <div class="foto">
            <img :src="item.img" :alt="item.heading">
          </div>
          <div class="cart">
            <h3>{{ item.heading }}</h3>
          </div>
        </router-link>

      </div>

      <img id="bgcizgi" height="auto" src="../../../public/assets/img/kirmizi-cizgi.png" width="100%"/>

    </section>

    </div>
</template>
<script>
import iso from "../../axios";
export default {
  data(){
    return{
      news:[],
      lang:[],
    }
  },
  created() {
    iso.get('news')
        .then(response => {
          this.news = response.data
        })
        .catch(error => {
          console.log(error);
        })
        iso.get('langfix')
        .then(response => {
          this.lang = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })

  },
  methods:{

  },
  beforeRouteLeave (to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
  beforeRouteEnter (to, from, next) {
    next();
    window.scrollTo(0, 0);
  }

}
</script>
<style scoped>
.header-haberler{
  position: relative;
  clear: both;
  width: 80%;
  margin-left:10%;
  margin-right:10%;
  text-align: left;
}

.header-haberler h3{
  font-family: bukis;
  color:#af1116;
  font-size:30px;
  margin:8px 0px 8px 0px;
}
.header-haberler h2{
  font-family: elyazi;
  font-size: 60px;
  margin:8px 0px 8px 0px;
  color:#af1116;
}


.duyurular-haberler{
  position: relative;
  clear: both;
  width: 100%;
}
.duyurular-haberler #bgcizgi{
  position: absolute;
  top:120px;
  left:0;
  width: 100%;
  z-index: -2;
}

.duyurular-haberler #duyurular{
  width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: stretch;
  grid-gap: 10px;
  justify-content: center;
}
.duyurular-haberler #duyurular .duyuru{
  width: 100vw;
  max-width: 350px;
  float: left;
  margin: 1.5vw;
  border-radius: 30px;
  background-color: #f5ebeb;
  color:#111111;
  text-decoration: none;
  transition: all ease .3s;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.duyurular-haberler #duyurular .duyuru:hover{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgb(255, 0, 0) 0px 30px 60px -30px;
  transform:  translateY(-20px);;
}
.duyurular-haberler #duyurular .duyuru .foto{
  width: 100%;
}
.duyurular-haberler #duyurular .duyuru .foto img{
  width: 100%;
  height: auto;
  border-radius: 30px 30px 0px 0px;
}
.duyurular-haberler #duyurular .duyuru .cart{
  padding:20px;
}
.duyurular-haberler #duyurular .duyuru h3{
  margin-bottom: 10px;
  color:#af1116;
  font-size: 20px;
  font-weight: 600;
}
</style>